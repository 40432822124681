import { WpSale } from 'web-platform-vuetify';
import { WpSaleSteps } from 'web-platform-vuetify/src/WpSale/wp-sale';
import Component from 'vue-class-component';
import {
	OrderItemType,
	LoyaltySymbols,
	ILoyaltyProvider,
	IsdUserLoyalty,
	PersonificationApiOrderItem,
	ErrorTypes,
	CartItemTypes,
	OrderSale,
} from 'web-platform-core-ui';

@Component
export default class VodaSale extends WpSale {
	public async ReservateCartAsync(): Promise<void> {
		let personId: number | null | undefined;
		let isdUserLoyalty: IsdUserLoyalty;

		if (
			this.Settings.RequiredLoyalty ||
			this.Cart.Items.some(
				(x) =>
					x.Type == CartItemTypes.InstructorsBundleLessons ||
					x.Type == CartItemTypes.InstructorsGroupLesson ||
					x.Type == CartItemTypes.InstructorsPersonalLesson ||
					x.Type == CartItemTypes.NewClubContract ||
					x.Type == CartItemTypes.ContractAdditionalService ||
					x.Type == CartItemTypes.AccountReplenishment
			)
		) {
			if (!this._context.CurrentIdentity.IsAuthentificated) {
				this._redirectToAuth = true;
				this._context.SignIn();
				return;
			}

			let loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
			let userLoyalty = await loyaltyProvider.GetIsdUserLoyaltyAsync();

			if (userLoyalty.Success) {
				let loyaltyList = await this._saleService.GetLoyaltyListAsync();

				if (
					loyaltyList.Success &&
					loyaltyList.Data != null &&
					loyaltyList.Data.length > 0 &&
					(this.Settings.LoyaltyId == null || this.Settings.LoyaltyId == 0)
				)
					this.Settings.LoyaltyId = loyaltyList.Data[0].Id;

				if (userLoyalty.Data == null) {
					this.LoyaltyDialog = true;
					return;
				} else {
					personId = userLoyalty.Data.PersId;
					isdUserLoyalty = userLoyalty.Data;
				}
			} else {
				this._notificationService.Error('', userLoyalty?.ErrorMessage ?? this.Terms.InternalError);
				return;
			}
		}

		this.HasPersonification = false;
		this.NextLoading = true;
		this.LoadingOverlay = true;
		let reservationResult = await this._saleService.ReservateAsync(this.Cart, personId);

		if (!reservationResult.Success || reservationResult.Data == null) {
			if (reservationResult.ErrorType == ErrorTypes.QuotaExceededForTariff) {
				this._notificationService.Error('', this.Terms.CartItemCountError);
			} else if (reservationResult.ErrorType == ErrorTypes.TariffNotAvailableForSale) {
				this._notificationService.Error('', this.Terms.CartItemNotAvailableError);
			} else if (reservationResult.ErrorType == ErrorTypes.CardWithSoTypeTariff) {
				this._notificationService.Error('', this.Terms.CardHaveTariffWithSoType);
			} else if (reservationResult.ErrorType == ErrorTypes.LessonNotAvailable) {
				this._notificationService.Error('', this.Terms.LessonNotAvailable);
			} else if (reservationResult.ErrorType == ErrorTypes.RewriteTariffNotAvailable) {
				this._notificationService.Error('', this.Terms.CardHaveNotUsedTariff);
			} else {
				this._notificationService.Error('', reservationResult.ErrorMessage ? reservationResult.ErrorMessage : this.Terms.InternalError);
			}

			this.ShowSteps = false;

			this.$nextTick(() => {
				this.$emit('LoadingFinished');
				this.NextLoading = false;
				this.ShowSteps = true;
				this.LoadingOverlay = false;
			});

			return;
		}

		if (
			reservationResult.Data.Items.some(
				(x) =>
					(x.Type == OrderItemType.TariffPersonification ||
						x.Type == OrderItemType.TariffBarcodePesonification ||
						x.Type == OrderItemType.TariffRewritablePesonification) &&
					x.AdditionalInfo != undefined &&
					x.AdditionalInfo.Info != undefined &&
					x.AdditionalInfo.Info != null &&
					x.AdditionalInfo.Info.includes('_unknow')
			)
		) {
			this.HasPersonification = true;
		}

		this.Cart.Reset();
		this.NextLoading = false;

		this.OrderId = reservationResult.Data.Id;
		parent.postMessage({ status: 'reserved', data: { order_id: this.OrderId, order: reservationResult.Data } }, '*');
		if (this.HasPersonification) {
			await this.HandlingItems(reservationResult.Data, isdUserLoyalty!);
			await this.PersonificateOrder();
		} else {
			this.Step = WpSaleSteps.PayConfirm;
		}
		this.$emit('LoadingFinished');
		this.LoadingOverlay = false;
	}

	protected HandlingItems(order: OrderSale, userLoyalty: IsdUserLoyalty) {
		let items = order.Items.filter(
			(x) =>
				(x.Type == OrderItemType.TariffPersonification ||
					x.Type == OrderItemType.TariffBarcodePesonification ||
					x.Type == OrderItemType.TariffRewritablePesonification) &&
				x.AdditionalInfo != undefined &&
				x.AdditionalInfo.Info != undefined &&
				x.AdditionalInfo.Info != null &&
				x.AdditionalInfo.Info.includes('_unknow')
		);

		this.PersonificationItems.splice(0);
		items.forEach((element) => {
			let personification = new PersonificationApiOrderItem();
			personification.Id = element.Id;
			personification.PersonId = userLoyalty.PersId;
			personification.FirstName = userLoyalty.FirstName;
			personification.LastName = userLoyalty.LastName;
			personification.SecondName = userLoyalty.SecondName;
			if (userLoyalty.Birthdate != null) personification.BirthDate = this.DateHandler.Parse(userLoyalty.Birthdate).Format('YYYY-MM-DD');
			if (userLoyalty.Gender != null) personification.Gender = userLoyalty.Gender.toString();
			personification.Phone = userLoyalty.Phone;
			personification.IsdTariffpersonification = element.AdditionalInfo.PersonificationQuestionnaire.Members;

			this.PersonificationItems.push(personification);
		});
	}
}
