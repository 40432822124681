import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Framework } from 'vuetify';
import { wpComponents } from 'web-platform-vuetify';
import ru from 'vuetify/src/locale/ru';
import colors from 'vuetify/lib/util/colors';
import { Resize, Scroll } from 'vuetify/lib/directives';
import * as vuetifyLib from 'vuetify/lib';
import VodaSale from './voda-sale';

for (const vComponentName in vuetifyLib) {
	if (vComponentName.startsWith('V')) {
		type objectKey = keyof typeof vuetifyLib;
		Vue.component(vComponentName, vuetifyLib[vComponentName as objectKey]);
	}
}

wpComponents['wp-sale'] = VodaSale;

for (const wpComponentName in wpComponents) {
	type objectKey = keyof typeof wpComponents;
	Vue.component(wpComponentName, Vue.extend(wpComponents[wpComponentName as objectKey]));
}

Vue.use(Vuetify, { directives: { Resize, Scroll } });

export default new Vuetify({
	lang: {
		locales: { ru },
		current: 'ru',
	},
	theme: {
		themes: {
			light: {
				secondary: colors.blue.lighten3,
			},
		},
	},
});

declare module 'vue/types/vue' {
	export interface Vue {
		$vuetify: Framework;
	}
}
